.tr-ACC td{
  font-weight: 600;
}
.tr-TotalAllIncludeVAT td{
  background-color: #FFFF00 !important;
  font-weight: 600;
}
.tr-AmountRouteMonth td, .tr-TotalFixed td{
  background-color: #FABF8F !important;
  font-weight: 600;
  height: 60px !important;
}
.tr-VehicleFlex td, .tr-TotalFlexibled td{
  background-color: #C4D79B !important;
  font-weight: 600;
}
.tr-TotalAll td {
  font-weight: 600;
  height: 80px !important;
}
.tr-TotalAll td[id*="Amount"]{
  background-color: #ABAA94 !important;
}
.tr-AverageMonth td{
  height: 60px !important;
  font-weight: 600;
}